import React from "react";

const Footer = () => {
  return (
    <footer>
      <div>Created by</div>
      <p>
        <a href="https://github.com/Stemonitis">Stemonitis</a> © 2020
      </p>
    </footer>
  );
};

export default Footer;
